import { EndCustomer } from "@api/graphql/generated/generated-types";
import { formatPhoneNumber } from "@src/common/lib/textUtils";

export interface EndCustomerDisplay {
  title: string;
  subTitle: string;
  idDisplay: string;
}

export function makeEndCustomerDisplay(
  endCustomer: EndCustomer | undefined
): EndCustomerDisplay {
  if (!endCustomer) {
    return {
      title: "",
      subTitle: "",
      idDisplay: "",
    };
  }
  const companyName = endCustomer.companyName;
  const fullName = fullNameDisplay(
    endCustomer.firstName || undefined,
    endCustomer.lastName || undefined
  );
  const email: string = endCustomer.contactEmail;
  const phone: string = formatPhoneNumber(endCustomer.contactPhoneNumber);
  return {
    title: companyName || fullName || email || "",
    subTitle: `${phone || ""}`,
    idDisplay: `${endCustomer.displayId}`,
  };
}

export function endCustomerDisplay(
  endCusomer: EndCustomer | undefined
): string {
  if (!endCusomer) {
    return "";
  }
  const fullName = fullNameDisplay(
    endCusomer.firstName || undefined,
    endCusomer.lastName || undefined
  );
  if (endCusomer.companyName && fullName) {
    return `${endCusomer.displayId} - ${endCusomer.companyName} (${fullName})`;
  }
  if (endCusomer.companyName) {
    return `${endCusomer.displayId} - ${endCusomer.companyName}`;
  }

  return `${endCusomer.displayId} - (${fullName})`;
}

function fullNameDisplay(
  firstName: String | undefined,
  lastName: String | undefined
): string | undefined {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return `${firstName}`;
  }
  if (lastName) {
    return `${lastName}`;
  }
  return undefined;
}
