import React from "react";
import { TYPE } from "baseui/select";
import { Label, Select } from "@src/common/components";
import { useCreateOrderContext } from "../utils/CreateOrderContextProvider";

function MonthlyScheduleSelector({
  selectedCustomDaysOfTheMonth,
  setSelectedCustomDaysOfTheMonth,
}) {
  const daysOfMonth = Array.from({ length: 31 }, (_, i) => ({
    id: i + 1,
    label: (i + 1).toString(),
  }));

  const selectedValues = daysOfMonth.filter((day) =>
    selectedCustomDaysOfTheMonth.includes(day.id)
  );

  const handleDaySelection = (params) => {
    const selectedDays = params.value.map((item) => item.id);
    setSelectedCustomDaysOfTheMonth(selectedDays);
  };

  return (
    <div className="flex flex-col gap-2">
      <Label className="block text-sm font-medium text-gray-700 truncate">
        Select days
      </Label>
      <Select
        options={daysOfMonth}
        value={selectedValues}
        placeholder="Select days"
        multi
        type={TYPE.select}
        onChange={handleDaySelection}
      />
    </div>
  );
}

export default MonthlyScheduleSelector;
