import {
  CostCenter,
  EndCustomer,
  PreviewPriceResult,
  RecurrenceScheduleType,
  ServiceType,
} from "@api/graphql/generated/generated-types";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { CustomerAction } from "../components/GeneralForm/GeneralForm";
import { EditableSurchargePricingRule } from "../components/SurchagePricingRulesForm";
import { OrderDetailsWidgetDataProps } from "@src/common/components/Widgets/OrderDetailsWidget";
import { DriverMapWidgetProps } from "@src/common/components/Widgets/DriverMapWidget";

export enum CustomRecurrenceScheduleType {
  DoesNotRepeat = "DOES_NOT_REPEAT",
}

export type ExtendedRecurrenceScheduleType =
  | RecurrenceScheduleType
  | CustomRecurrenceScheduleType;

export type Week = {
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
};

interface ContextState {
  isNewCustomerFormOpen: boolean;
  setisNewCustomerFormOpen: (isOpen: boolean) => void;
  autoFillTypeValue: { label: string; id: string }[];
  setAutoFillTypeValue: (
    value: { label: CustomerAction; id: string }[]
  ) => void;
  selectedCustomer: EndCustomer | undefined;
  setSelectedCustomer: (customer: EndCustomer | undefined) => void;
  chosenCostCenter: CostCenter | undefined;
  setChosenCostCenter: (costCenter: CostCenter | undefined) => void;
  isCostCenterModalOpen: boolean;
  setIsCostCenterModalOpen: (isOpen: boolean) => void;
  selectedServiceType: ServiceType | undefined;
  setSelectedServiceType: (serviceType: ServiceType | undefined) => void;
  previewPrice: PreviewPriceResult | undefined;
  setPreviewPrice: (price: PreviewPriceResult | undefined) => void;
  surchargePricings: EditableSurchargePricingRule[];
  setSurchargePricings: (rows: EditableSurchargePricingRule[]) => void;
  recurrenceSchedule: ExtendedRecurrenceScheduleType;
  setRecurrenceSchedule: (
    recurrenceSchedule: ExtendedRecurrenceScheduleType
  ) => void;
  selectedDaysWeek: Week;
  setSelectedDaysWeek: any;
  selectedCustomDaysOfTheMonth: any[];
  setSelectedCustomDaysOfTheMonth: (days: any[]) => void;
  scheduleDates: {
    startDate: Date | undefined;
    endDate: Date | null;
  };
  setScheduleDates: any;
  query: string;
  setQuery: (query: string) => void;
  orderDetails: OrderDetailsWidgetDataProps[];
  setOrderDetails: (orderDetails: OrderDetailsWidgetDataProps[]) => void;
  mapWidgetState: DriverMapWidgetProps | undefined;
  setMapWidgetState: (mapWidgetState: DriverMapWidgetProps | undefined) => void;
  selectedDuration: string;
  setSelectedDuration: (duration: string) => void;
}

const CreateOrderContext = createContext<ContextState | undefined>(undefined);

interface CreateOrderContextProviderProps {
  children: ReactNode;
}

export const CreateOrderContextProvider: React.FC<CreateOrderContextProviderProps> =
  ({ children }) => {
    const [isNewCustomerFormOpen, setisNewCustomerFormOpen] = useState(false);
    const [autoFillTypeValue, setAutoFillTypeValue] = useState([
      { label: CustomerAction.PickUpAtCustomer, id: "1" },
    ]);
    const [selectedCustomer, setSelectedCustomer] =
      useState<EndCustomer | undefined>(undefined);
    const [chosenCostCenter, setChosenCostCenter] =
      useState<CostCenter | undefined>(undefined);
    const [isCostCenterModalOpen, setIsCostCenterModalOpen] = useState(false);
    const [selectedServiceType, setSelectedServiceType] =
      useState<ServiceType | undefined>(undefined);
    const [previewPrice, setPreviewPrice] =
      useState<PreviewPriceResult | undefined>(undefined);
    const [surchargePricings, setSurchargePricings] = useState<
      EditableSurchargePricingRule[]
    >([]);
    const [recurrenceSchedule, setRecurrenceSchedule] =
      useState<ExtendedRecurrenceScheduleType>(
        CustomRecurrenceScheduleType.DoesNotRepeat
      );
    const [selectedDaysWeek, setSelectedDaysWeek] = React.useState({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    });
    const [selectedCustomDaysOfTheMonth, setSelectedCustomDaysOfTheMonth] =
      useState<any[]>([]);
    const [scheduleDates, setScheduleDates] = useState({
      startDate: new Date(),
      endDate: null,
    });
    const [query, setQuery] = useState("");
    const [orderDetails, setOrderDetails] = useState<
      OrderDetailsWidgetDataProps[]
    >([]);
    const [mapWidgetState, setMapWidgetState] =
      useState<DriverMapWidgetProps | undefined>(undefined);
    const [selectedDuration, setSelectedDuration] = useState("noStopDates");

    return (
      <CreateOrderContext.Provider
        value={{
          surchargePricings,
          setSurchargePricings,
          isNewCustomerFormOpen,
          setisNewCustomerFormOpen,
          autoFillTypeValue,
          setAutoFillTypeValue,
          selectedCustomer,
          setSelectedCustomer,
          chosenCostCenter,
          setChosenCostCenter,
          isCostCenterModalOpen,
          setIsCostCenterModalOpen,
          selectedServiceType,
          setSelectedServiceType,
          previewPrice,
          setPreviewPrice,
          recurrenceSchedule,
          setRecurrenceSchedule,
          selectedDaysWeek,
          setSelectedDaysWeek,
          selectedCustomDaysOfTheMonth,
          setSelectedCustomDaysOfTheMonth,
          scheduleDates,
          setScheduleDates,
          query,
          setQuery,
          orderDetails,
          setOrderDetails,
          mapWidgetState,
          setMapWidgetState,
          selectedDuration,
          setSelectedDuration,
        }}
      >
        {children}
      </CreateOrderContext.Provider>
    );
  };

export const useCreateOrderContext = () => {
  const context = useContext(CreateOrderContext);
  if (context === undefined) {
    throw new Error(
      "useCreateOrderContext must be used within a CreateOrderContextProvider"
    );
  }
  return context;
};
