import { Control, FieldValues } from "react-hook-form";
import { Input, Label, DatePicker } from "@src/common/components";
import { Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";

interface FormInputProps {
  name: string;
  label: string;
  control: Control<FieldValues>;
  errors: any;
  isDatePicker?: boolean;
  type?: string;
  placeholder?: string;
  required?: boolean;
  min?: number;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  control,
  errors,
  isDatePicker = false,
  type = "text",
  placeholder = "",
  required = false,
  min = undefined,
}) => (
  <div className="flex flex-col justify-between">
    <Label
      htmlFor={name}
      className="block text-xs font-medium text-gray-700 truncate"
    >
      {label}{" "}
      {!required && <span className="text-slate-400 text-xs">(Optional)</span>}
      <span className="text-red-500 ml-3">
        <ErrorMessage errors={errors} name={name} />
      </span>
    </Label>
    <Controller
      control={control}
      name={name}
      render={({ field }) =>
        isDatePicker ? (
          <DatePicker
            size="mini"
            value={field.value}
            formatString={"MMM do, yyyy"}
            onChange={({ date }) => field.onChange(date)}
            minDate={new Date()}
            error={
              errors?.shipments && errors?.shipments[0]?.shipmentDate?.message
            }
          />
        ) : (
          <Input
            size="mini"
            value={field.value || ""}
            onChange={(event) => field.onChange(event.currentTarget.value)}
            onBlur={field.onBlur}
            placeholder={placeholder}
            type={type}
            error={errors[name] ? true : false}
            min={min}
          />
        )
      }
    />
  </div>
);

export default FormInput;
