import React from "react";
import { DatePicker, Label } from "@src/common/components";
import { RecurrenceScheduleType } from "@api/graphql/generated/generated-types";
import moment from "moment";

function StartDateEndDateSelector({
  scheduleDates,
  setScheduleDates,
  recurrenceSchedule,
}) {
  const handleStartDateChange = (date) => {
    if (date) {
      setScheduleDates({ ...scheduleDates, startDate: date });
    } else {
      setScheduleDates({ ...scheduleDates, startDate: null });
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      setScheduleDates({ ...scheduleDates, endDate: date });
    } else {
      setScheduleDates({ ...scheduleDates, endDate: null });
    }
  };

  const generateScheduleMessage = () => {
    const { startDate, endDate } = scheduleDates;
    const formattedStartDate = startDate
      ? startDate.toLocaleDateString()
      : "N/A";
    const formattedEndDate = endDate ? endDate.toLocaleDateString() : "N/A";

    switch (recurrenceSchedule) {
      case RecurrenceScheduleType.Daily:
        if (startDate && endDate) {
          return `Daily recurrence between ${formattedStartDate} and ${formattedEndDate}`;
        } else if (startDate) {
          return `Daily recurrence starting ${formattedStartDate}`;
        }
        break;
      case RecurrenceScheduleType.Biweekly:
        if (!startDate) {
          return "Biweekly recurrence not set"; // or a similar message indicating the absence of a start date
        }

        const weekday = moment(startDate).format("dddd");

        if (endDate) {
          const formattedEndDate = endDate.toLocaleDateString();
          return `Every other ${weekday}, until ${formattedEndDate}`;
        } else {
          return `Every other ${weekday}`;
        }

      // Handle other cases if needed
      default:
        return "";
    }
    return "";
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 justify-between">
        <div className="flex-auto flex flex-col">
          <Label className="block text-xs font-medium text-gray-700">
            Schedule Start Date
          </Label>
          <DatePicker
            size="mini"
            minDate={new Date()}
            value={scheduleDates.startDate}
            onChange={({ date }) => handleStartDateChange(date)}
            clearable={true}
          />
        </div>

        <div className="flex-auto flex flex-col">
          <Label className="block text-xs font-medium text-gray-700">
            Schedule End Date (Optional)
          </Label>
          <DatePicker
            size="mini"
            minDate={new Date()}
            value={scheduleDates.endDate}
            onChange={({ date }) => handleEndDateChange(date)}
            placeholder="Select end date"
            clearable={true}
          />
        </div>
      </div>

      <p className="text-xs text-yellow-500">{generateScheduleMessage()}</p>
    </div>
  );
}

export default StartDateEndDateSelector;
