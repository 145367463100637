import React from "react";
import { Label, Select } from "@src/common/components";
import {
  CustomRecurrenceScheduleType,
  ExtendedRecurrenceScheduleType,
} from "../utils/CreateOrderContextProvider";
import { RecurrenceScheduleType } from "@api/graphql/generated/generated-types";

type Props = {
  recurrenceSchedule: ExtendedRecurrenceScheduleType;
  handleChange: (params: any) => void;
  isRecurringOnly?: boolean;
};

function SelectOrderRecurrence({
  recurrenceSchedule,
  handleChange,
  isRecurringOnly = false,
}: Props) {
  const recurrenceScheduleOptions = [
    {
      label: "Does Not Repeat",
      id: CustomRecurrenceScheduleType.DoesNotRepeat,
    },
    { label: "Daily", id: RecurrenceScheduleType.Daily },
    {
      label: "Custom Days Of The Week",
      id: RecurrenceScheduleType.CustomDaysOfTheWeek,
    },
    {
      label: "Custom Days Of The Month",
      id: RecurrenceScheduleType.CustomDaysOfTheMonth,
    },
    { label: "Biweekly", id: RecurrenceScheduleType.Biweekly },
    { label: "Monthly On First", id: RecurrenceScheduleType.MonthlyOnFirst },
    { label: "Monthly On Last", id: RecurrenceScheduleType.MonthlyOnLast },
  ];
  if (isRecurringOnly) {
    recurrenceScheduleOptions.shift();
  }
  return (
    <>
      <Label
        htmlFor={"Service Type"}
        className="block text-xs font-medium text-gray-700 truncate"
      >
        Order Recurrence
      </Label>
      <Select
        options={recurrenceScheduleOptions}
        value={recurrenceScheduleOptions.filter(
          (option) => option.id === recurrenceSchedule
        )}
        size="mini"
        clearable={false}
        onChange={handleChange}
        searchable={false}
      />
    </>
  );
}

export default SelectOrderRecurrence;
