import React from "react";
import { Checkbox } from "baseui/checkbox";
import { Label, RadioWithLabel } from "@src/common/components";
import { dayAbbreviations } from "../utils/DateConstants";

type WeekdaySelectorProps = {
  selectedDayWeek: string | Record<string, boolean>;
  setSelectedDayWeek: React.Dispatch<
    React.SetStateAction<string | Record<string, boolean>>
  >;
  multiple?: boolean;
  label?: string;
};

function WeekdaySelector({
  selectedDayWeek,
  setSelectedDayWeek,
  multiple = false,
  label = "Repeat on",
}: WeekdaySelectorProps) {
  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (multiple) {
      setSelectedDayWeek((prev) => ({
        ...(prev as Record<string, boolean>),
        [value]: !(prev as Record<string, boolean>)[value],
      }));
    } else {
      setSelectedDayWeek(value);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label className="block text-sm font-medium text-gray-700 truncate">
        {label}
      </Label>
      <div className="flex flex-wrap px-0 gap-5">
        {multiple
          ? Object.keys(dayAbbreviations).map((day) => (
              <Checkbox
                key={day}
                value={day}
                checked={(selectedDayWeek as Record<string, boolean>)[day]}
                onChange={handleSelectionChange}
              >
                <span className="-ml-1.5">{dayAbbreviations[day]}</span>
              </Checkbox>
            ))
          : Object.keys(dayAbbreviations).map((day) => (
              <RadioWithLabel
                key={day}
                value={day}
                checked={selectedDayWeek === day}
                onChange={handleSelectionChange}
                htmlFor={`radio-${day}`}
              >
                <span className="-ml-1.5">{dayAbbreviations[day]}</span>
              </RadioWithLabel>
            ))}
      </div>
    </div>
  );
}

export default WeekdaySelector;
