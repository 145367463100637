import "react-datepicker/dist/react-datepicker.css";
import FormInput from "./FormInput";
import {
  CustomRecurrenceScheduleType,
  ExtendedRecurrenceScheduleType,
  Week,
} from "../utils/CreateOrderContextProvider";
import { RecurrenceScheduleType } from "@api/graphql/generated/generated-types";
import WeekdaySelector from "./WeekdaySelector";
import MonthlyScheduleSelector from "./MonthlyScheduleSelector";
import { Control, FieldErrors, FieldValues } from "react-hook-form";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  recurrenceSchedule: ExtendedRecurrenceScheduleType;
  selectedDaysWeek: Week;
  setSelectedDaysWeek: any;
  selectedCustomDaysOfTheMonth: any[];
  setSelectedCustomDaysOfTheMonth: (days: any[]) => void;
};

function ScheduleTypeSelector({
  control,
  errors,
  recurrenceSchedule,
  selectedDaysWeek,
  setSelectedDaysWeek,
  selectedCustomDaysOfTheMonth,
  setSelectedCustomDaysOfTheMonth,
}: Props) {
  return (
    <>
      {recurrenceSchedule === CustomRecurrenceScheduleType.DoesNotRepeat && (
        <FormInput
          name="shipments.0.shipmentDate"
          label="Shipment Date"
          control={control}
          errors={errors}
          isDatePicker={true}
        />
      )}
      {recurrenceSchedule === RecurrenceScheduleType.CustomDaysOfTheWeek && (
        <WeekdaySelector
          selectedDayWeek={selectedDaysWeek}
          setSelectedDayWeek={setSelectedDaysWeek}
          multiple={true}
        />
      )}
      {recurrenceSchedule === RecurrenceScheduleType.CustomDaysOfTheMonth && (
        <MonthlyScheduleSelector
          selectedCustomDaysOfTheMonth={selectedCustomDaysOfTheMonth}
          setSelectedCustomDaysOfTheMonth={setSelectedCustomDaysOfTheMonth}
        />
      )}
      {recurrenceSchedule === RecurrenceScheduleType.MonthlyOnFirst && (
        <WeekdaySelector
          selectedDayWeek={selectedDaysWeek}
          setSelectedDayWeek={setSelectedDaysWeek}
          multiple={true}
        />
      )}
      {recurrenceSchedule === RecurrenceScheduleType.MonthlyOnLast && (
        <WeekdaySelector
          selectedDayWeek={selectedDaysWeek}
          setSelectedDayWeek={setSelectedDaysWeek}
          multiple={true}
        />
      )}
    </>
  );
}

export default ScheduleTypeSelector;
