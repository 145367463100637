import React, { useEffect, useState } from "react";
import { Label, Select, Tooltip } from "@src/common/components";
import { Controller, useFormContext } from "react-hook-form";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useCreateOrderContext } from "@src/orders/create/utils/CreateOrderContextProvider";
import {
  CostCenter,
  useGetCostCentersLazyQuery,
} from "@api/graphql/generated/generated-types";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";

interface Props {}

const CostCenterSelector: React.FC<Props> = () => {
  const [retrievedCostCenters, setRetrievedCostCenters] = useState<
    CostCenter[]
  >([]);
  const [fetchCostCenters, { data, loading, error }] =
    useGetCostCentersLazyQuery({
      fetchPolicy: "no-cache",
    });
  const { control, watch, setValue } = useFormContext();
  const {
    selectedCustomer,
    setChosenCostCenter,
    setIsCostCenterModalOpen,
    chosenCostCenter,
  } = useCreateOrderContext();
  const { showToast } = useCourieStore();

  const endCustomerId = watch("endCustomerId");

  useEffect(() => {
    if (selectedCustomer) {
      setChosenCostCenter(undefined);
      setValue("costCenterId", "");
      handleCostCenterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  useEffect(() => {
    if (chosenCostCenter) {
      setValue("costCenterId", [chosenCostCenter]);
      handleCostCenterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCostCenter]);

  const handleCostCenterChange = () => {
    if (selectedCustomer) {
      fetchCostCenters({
        variables: {
          endCustomerIds: selectedCustomer.id,
        },
      })
        .then((response) => {
          const costCenters = response.data?.costCenters || [];
          setRetrievedCostCenters(costCenters);
        })
        .catch((error) => {
          showErrorToast(error, showToast);
        });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <Label
          htmlFor="costCenterId"
          className="text-xs font-medium text-gray-700  flex justify-between"
        >
          <div className="truncate">
            Cost Center{" "}
            <span className="text-slate-400 text-xs">(Optional)</span>
          </div>
          {endCustomerId.length > 0 && (
            <span
              className="text-xs hover:underline hover:cursor-pointer text-blue-500 truncate"
              onClick={() => setIsCostCenterModalOpen(true)}
            >
              Add New Cost Center
            </span>
          )}
        </Label>
        <Controller
          control={control}
          name="costCenterId"
          render={({ field: { value, onChange } }) => (
            <Select
              size="mini"
              isLoading={loading}
              options={retrievedCostCenters}
              labelKey="name"
              valueKey="id"
              onChange={({ value }) => {
                const selectedCostCenter: CostCenter | undefined =
                  value[0] as CostCenter;
                setChosenCostCenter(selectedCostCenter);
                onChange(value);
              }}
              value={value}
              disabled={endCustomerId.length === 0}
            />
          )}
        />
      </div>
    </>
  );
};

export default CostCenterSelector;
